import { Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';
import { FaHome } from 'react-icons/fa';

import { Header, SEO } from '@/layout';
import { t } from '@/utils/i18n';

const NotFoundPage = () => {
  return (
    <>
      <SEO
        title={t(`pages.notfound.title`)}
        description={t(`pages.notfound.description`)}
      />
      <Header shadow="md" />
      <Flex
        height="70vh"
        align="center"
        justify="center"
        flexDirection="column"
        maxW="80vw"
        m="auto"
        wordBreak="keep-all"
      >
        <VStack
          justify="center"
          spacing="4"
          as="section"
          mt={[`20`, null, `40`]}
          textAlign="center"
        >
          <Heading m="auto">{t(`pages.notfound.heading`)}</Heading>
          <Text fontSize={{ md: `xl` }}>
            {t(`pages.notfound.message1`)}
            <br />
            {t(`pages.notfound.message2`)}
          </Text>
          <NextLink href="/" passHref>
            <Button
              as="a"
              aria-label="홈으로 돌아가기"
              leftIcon={<FaHome />}
              colorScheme="teal"
              size="lg"
            >
              {t(`pages.notfound.back-to-home`)}
            </Button>
          </NextLink>
        </VStack>
      </Flex>
    </>
  );
};

export default NotFoundPage;
